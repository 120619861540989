import { PlusCircleFilled } from "@ant-design/icons";
import { List, useModal } from "@refinedev/antd";
import { Button, Checkbox, Form } from "antd";

import React from "react";
import { ExploreList } from "./component/list/explore-list";
import { PopupNewExplore } from "./component/list/popup-new-explore";

export const ListExplore: React.FC = () => {
  const [form] = Form.useForm();
  const { show, modalProps } = useModal();

  return (
    <Form
      form={form}
      name="explore-list"
      initialValues={{
        form_loading: false,
      }}
    >
      <PopupNewExplore modalProps={modalProps} />

      <List
        title={
          <div>
            <Button type="primary" size="large" icon={<PlusCircleFilled />} onClick={show}>
              Add new explore
            </Button>
          </div>
        }
        headerProps={{
          extra: false,
        }}
      >
        <ExploreList form={form} />
      </List>
      <Form.Item name="form_loading" hidden>
        <Checkbox />
      </Form.Item>
    </Form>
  );
};
