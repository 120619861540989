import { useGetIdentity, useNavigation, useOne } from "@refinedev/core";
import { PAGE_URL } from "@src/config";
import { mappingExpTypeToLabel } from "@src/constant/explore/mapping-title-new-explore";
import { getModeOfLocation } from "@src/util/fb-exploration/get-mode-of-location";

const formatExploration = (exploration: Record<string, any>) => {
  return {
    id: exploration.id,
    name: exploration.title,
    description: exploration.description ?? "",
    productCode: exploration.product_code,
    vizState: {
      chartType: exploration.chart_type,
      query: {
        measures: exploration.measures,
        dimensions: exploration.dimensions,
        timeDimensions: exploration.time_dimensions,
        filters: exploration.filter,
        segments: exploration.segments,
        order: exploration.order,
        limit: exploration.limit,
      },
      pivotConfig: exploration.pivot_config,
      type: mappingExpTypeToLabel[exploration.exp_type],
      product_code: exploration.product_code,
    },
  };
};
export const useGetExplorations = (id?: string) => {
  const { isEditMode } = getModeOfLocation();

  const { replace } = useNavigation();
  const { data: identifyOfUser } = useGetIdentity<any>();

  const { data: initValue, isLoading: isLoadingGetInitValue } = useOne({
    id,
    resource: `exploration/fb-exploration`,
    queryOptions: {
      enabled: !!id,
      onSuccess: (data) => {
        const owner = JSON.parse(data?.data?.owner?.replace(/'/g, '"') ?? "{}");
        const isOwnerOfConfig = owner?.email === identifyOfUser?.email;

        if (isEditMode && !isOwnerOfConfig) {
          replace(PAGE_URL.ERROR_403);
        }
      },
    },
  });
  const exploration = initValue?.data ? formatExploration(initValue?.data) : null;

  return {
    exploration,
    isLoading: isLoadingGetInitValue,
    owner: JSON.parse(initValue?.data?.owner?.replace(/'/g, '"') ?? "{}"),
  };
};
