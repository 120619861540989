import { CubeProvider } from "@cubejs-client/react";
import { useExploreContext } from "@src/contexts/explore-context";
import cubejs from "@cubejs-client/core";
import { config } from "@src/config";
import React from "react";
import { ChartResultCreate } from "../chart/chart-result-create";
import { ChartResultEdit } from "../chart/chart-result-edit";
import { ChartResultView } from "../chart/chart-result-view";
import { FormInstance } from "antd";

type ChartResultProps = {
  mode: "create" | "edit" | "view";
  exploration?: any;
  form?: FormInstance<any>;
};
export const ChartResultProvider: React.FC<ChartResultProps> = ({ form, mode, exploration }) => {
  const { cubeToken } = useExploreContext();
  const cubejsApi = cubejs(cubeToken, { apiUrl: config.CUBE_URL });

  return (
    <CubeProvider cubeApi={cubejsApi}>
      {mode === "create" && <ChartResultCreate form={form} />}
      {mode === "edit" && <ChartResultEdit exploration={exploration} form={form} />}
      {mode === "view" && <ChartResultView exploration={exploration} form={form} />}
    </CubeProvider>
  );
};
