import { Edit, ListButton } from "@refinedev/antd";
import { Card, Checkbox, Form, Spin } from "antd";
import React from "react";
import ExploreQueryNew from "@src/pages/dashboard/components/explore-builder-new";
import { useNavigation, useParsed } from "@refinedev/core";
import { useGetExplorations } from "@src/hooks/use-get-explorations";
import { ChartResultProvider } from "./form/chart-result-provider";
import { GeneralInfoWrapper } from "./form/general-info-wrapper";

export const FirebaseExploreShareView: React.FC = () => {
  const { push } = useNavigation();
  const [form] = Form.useForm();
  const { id } = useParsed();
  const { exploration, isLoading } = useGetExplorations(id as string);
  const formLoading = Form.useWatch("form_loading", form);

  if (isLoading) return <Spin />;
  return (
    <Edit
      isLoading={isLoading || !!formLoading}
      title="View exploration"
      headerButtons={<ListButton onClick={() => push("/")}>Explorations</ListButton>}
      contentProps={{
        className: "bg-transparent shadow-none card-custom",
      }}
      footerButtonProps={{
        hidden: true,
      }}
      wrapperProps={{
        className: "base-custom-container",
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          form_loading: false,
          name: exploration?.name,
          product_code: exploration?.productCode,
          description: exploration?.description,
        }}
      >
        <Card size="small" title={"General Information"} className="  mb-2">
          <div className="flex">
            <div className="w-3/4 mr-4">
              <GeneralInfoWrapper form={form} mode="edit" />
            </div>
            <div id="actions-wrapper" className="self-end 1/4" />
          </div>
        </Card>
        <div className="flex w-full space-x-2 h-[calc(100vh-80px)]">
          <Card
            size="small"
            title={"Query Builder"}
            className="query-builder w-1/3 p-0"
            classNames={{
              body: "h-[calc(100%-50px)] overflow-y-auto",
            }}
          >
            <ExploreQueryNew />
          </Card>
          <div className="w-2/3  h-full flex flex-col space-y-2">
            <ChartResultProvider mode="view" exploration={exploration} form={form} />
          </div>
        </div>
        <Form.Item name="form_loading" hidden>
          <Checkbox />
        </Form.Item>
      </Form>
    </Edit>
  );
};
